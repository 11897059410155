import { AppBar, Box, Button, Dialog, Link, List, ListItem, ListItemText, Toolbar, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// import ImageHeader from '../../../assets/images/icons/bgheader.png';
import ImageHeader from '../../../assets/images/icons/header_newfeed.png';
import { useSelector } from 'react-redux';
import styles from '../../../layout/MainLayout/Header/header.module.css';
import LogoNewfeed from '../Logo';
import LoginIcon from '@mui/icons-material/Login';
import NotificationNewFeed from '../Notification';
import router from 'routes/router';
import fetchClient from 'views/utilities/RestfulAPI';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import ConfigRouter from 'routes/config_router';
import ProfileSection from 'layout/MainLayout/Header/ProfileSection';
import Contact from './Contact';
import Navbar from '../Navbar/NavbarV2';
import MobileAppBar from '../Navbar/MobileAppBar';
import DialogUtil from 'ui-component/show_confirm_action';
import '../../../ui-component/mConfigurationLayout/FontList.css';
import { DATA_FONT } from 'store/constant';

const HeaderV2 = ({ dataNotification, dataContact, logo, nameFamily, dataSetting }) => {
    const mediumScreen = useMediaQuery('(max-width: 900px)');
    const theme = useTheme();
    // const [logo, setLogo] = useState('');
    // const [nameFamily, setNameFamily] = useState('');
    const [checkLogin, setCheckLogin] = useState(false);
    const navigate = useNavigate();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const customization = useSelector((state) => state.customization);
    const [dataMenu, setDataMenu] = useState('');
    const [font, setFont] = useState('');

    // const getData = async () => {
    //     const rst = await fetchClient.getRequest(router.SUB_URL.getInforFamilyHomePage);
    //     if (rst?.status === 200) {
    //         setLogo(rst.data?.logo?.config_logo);
    //         setNameFamily(rst.data?.infoFamily?.family_name);
    //     } else {
    //         console.log('rst', rst);
    //     }
    // };

    const getDataMenu = async () => {
        // getNumHomepapge
        const result = await fetchClient.getRequest(router.SUB_URL.getNumHomepapge);
        if (result.status === 200) {
            setDataMenu(result?.data || []);
        } else {
            DialogUtil.showQuestions(false, 'Lỗi kết nối không thể lấy được dữ liệu');
        }
    };

    const onClickLogin = () => {
        navigate(ConfigRouter.login);
    };

    const getFont = async () => {
        const result = await fetchClient.getRequest(router.SUB_URL.getFontFamily);
        if (result.status === 200) {
            setFont(result?.data[0]?.config_title || '');
            localStorage.setItem(DATA_FONT, result?.data[0]?.config_title || '');
        } else {
            DialogUtil.showQuestions(false, 'Lỗi kết nối không thể lấy được dữ liệu');
        }
    };

    useEffect(() => {
        const dataUser = localStorage.getItem('DATA_USER');
        if (dataUser) {
            setCheckLogin(true);
        }
        // getData();
        getDataMenu();
        getFont();
    }, []);
    return (
        <>
            {dataSetting?.is_show && dataContact?.length > 0 && <Contact data={dataContact} />}
            <AppBar
                enableColorOnDark
                position="sticky"
                color="inherit"
                elevation={0}
                sx={{
                    // bgcolor: '#000000',
                    backgroundColor: '#fff8a9',
                    backgroundImage: `url(${ImageHeader})`,
                    transition: 'none',
                    width: '100%',
                    boxShadow: '0 2px 3px 0 rgba(0,0,0,0.2)',
                    marginBottom: dataSetting?.banner?.length > 0 ? '0px' : '30px'
                }}
            >
                <Toolbar sx={{ paddingLeft: '16px !important' }}>
                    <Box sx={{ width: '100%' }}>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: { md: 'center', xs: 'space-between' },
                                alignItems: 'center',
                                position: 'realtive'
                            }}
                        >
                            {mediumScreen && <MobileAppBar dataSetting={dataSetting} dataMenu={dataMenu} />}
                            {/* <Box sx={{ width: '15%' }}></Box> */}
                            <Box
                                component="span"
                                sx={{
                                    marginLeft: { md: '10px', xs: '0px' },
                                    display: { xs: 'flex' },
                                    alignItems: { xs: 'center' },
                                    justifyContent: { md: 'center', xs: 'space-between' },
                                    flexDirection: 'column'
                                }}
                            >
                                <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '15px' }}>
                                    <LogoNewfeed linkLogo={logo} />
                                    <Box sx={{ paddingLeft: { xs: '10px', lg: '30px' } }}>
                                        <Typography
                                            variant="h1"
                                            sx={{
                                                color: customization?.themeDongHo?.backgroundColor,
                                                marginTop: '20px',
                                                fontSize: '25px',
                                                textAlign: 'center'
                                            }}
                                            className={styles.cssTitle}
                                            style={{ fontFamily: font ? font : 'defaultFamily' }}
                                        >
                                            {nameFamily ? `Dòng họ ${nameFamily}` : 'Gia Phả Đại Việt'}
                                        </Typography>
                                    </Box>
                                </div>
                                {!mediumScreen && dataSetting?.is_show && (
                                    <Navbar
                                        checkLogin={checkLogin}
                                        onClickLogin={onClickLogin}
                                        dataSetting={dataSetting}
                                        dataMenu={dataMenu}
                                    />
                                )}
                            </Box>

                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    position: { md: 'absolute', xs: 'relative' },
                                    right: { md: '40px', xs: '0px' }
                                }}
                            >
                                {
                                    checkLogin && <ProfileSection />
                                    //  (
                                    //     <>
                                    //         <Link
                                    //             underline="none"
                                    //             component="button"
                                    //             variant="h4"
                                    //             onClick={onClickLogin}
                                    //             sx={{ display: { md: 'block', xs: 'none' } }}
                                    //         >
                                    //             Đăng nhập
                                    //         </Link>
                                    //         <Box sx={{ display: { md: 'none', xs: 'block' } }}>
                                    //             <button
                                    //                 size="small"
                                    //                 aria-label="account of current user"
                                    //                 aria-controls="menu-appbar"
                                    //                 aria-haspopup="true"
                                    //                 onClick={onClickLogin}
                                    //                 variant="contained"
                                    //                 style={{
                                    //                     backgroundColor: customization?.themeDongHo?.backgroundColor,
                                    //                     color: 'white',
                                    //                     padding: '4px 8px',
                                    //                     marginRight: '10px',
                                    //                     border: 'none',
                                    //                     borderRadius: '5px'
                                    //                 }}
                                    //             >
                                    //                 <LoginIcon />
                                    //             </button>
                                    //         </Box>
                                    //     </>
                                    // )
                                }
                            </Box>
                        </Box>
                    </Box>
                </Toolbar>
                {dataNotification && dataNotification.length > 0 && dataSetting.is_show && (
                    <NotificationNewFeed dataNoti={dataNotification} />
                )}
            </AppBar>
        </>
    );
};

export default HeaderV2;
